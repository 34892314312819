/******* screen-small.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
@font-face {
  font-family: "EmpiricaHeadlineRegular";
  src: url("/extras/fonts/EmpiricaHeadline-Regular.woff2") format("woff2"), url("/extras/fonts/EmpiricaHeadline-Regular.woff") format("woff");
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
.cbdModule {
  float: left;
  width: 100%;
}
#view .cbdModule.is-empty {
  display: none;
}
.cbDynamicContent__element {
  float: left;
  width: 100%;
}
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
video {
  float: left;
  width: 100%;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 18px;
  margin-bottom: 18px;
}
.unit > .body,
.unit > form > .body {
  margin: 0 0;
  width: 100%;
}
.cb-part-body {
  margin-top: 0;
  margin-bottom: 0;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}
.lead {
  margin-bottom: 0 !important;
}
.list {
  margin-top: 0.2em !important;
  margin-bottom: 0.2em !important;
}
.gist,
.dent {
  display: list-item;
  list-style-position: outside;
}
.gist {
  margin-left: 16px;
}
.dent {
  margin-left: 32px;
}
.line {
  margin-top: 0;
  margin-bottom: 0;
  padding: 2px 0;
  font-size: 0;
  line-height: 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #4d4d4d;
  color: #4d4d4d;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
  margin-left: 14px;
}
.link,
.load {
  background-repeat: no-repeat !important;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
.grid table {
  border-collapse: collapse;
}
.cb-table-layout-fixed {
  table-layout: fixed;
}
.grid td,
.grid th {
  border-width: 0;
  border-style: solid;
  border-color: #000;
  vertical-align: top;
}
.horz td,
.horz th {
  padding: 5px 1px;
}
.vert td,
.vert th {
  padding: 1px 4px;
}
.horz .trim td,
.horz .trim th {
  border-width: 1px 0;
}
.vert .trim td,
.vert .trim th {
  border-width: 0 1px;
}
.grid th {
  font-weight: bold;
  background: transparent;
  text-align: left;
}
.vert > .beam td.odd,
.horz > .beam > .odd {
  background: rgba(26, 26, 26, 0);
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 210;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 211;
  color: #404040;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset {
  padding: 0.2em 0 0.5em;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding-bottom: 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  margin-right: 15px;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
#disp.srch a:visited {
  color: #230009;
}
.srch .text {
  -webkit-appearance: searchfield;
  -moz-appearance: searchfield;
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  padding-bottom: 0.8em;
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #3c000f;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #3c000f;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
#disp.two-step-verification {
  z-index: 3100;
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #3c000f;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #3c000f;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .cb-table-3up.vert {
    position: relative;
    display: block;
    border: 1px solid #000;
  }
  .cb-table-3up.vert td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-3up.vert > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-3up.vert > tbody tr {
    display: inline-block;
    border-left: 1px solid #000;
  }
  .cb-table-3up.vert > tbody tr:first-child {
    border: 0;
  }
  .cb-table-head .cb-table-3up.vert > thead {
    display: block;
    float: left;
  }
  .cb-table-head .cb-table-3up.vert > thead > tr {
    display: block;
  }
  .cb-table-head .cb-table-3up.vert > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot {
    display: block;
    float: right;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot > tr {
    display: block;
  }
  .cb-table-foot .cb-table-3up.vert > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-both .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 1px solid #000;
  }
  .cb-table-both .cb-table-3up.horz td {
    display: block;
    border: 0;
    padding: 1px 4px;
  }
  .cb-table-both .cb-table-3up.horz > tbody {
    position: relative;
    display: block;
    overflow-x: auto;
    width: auto;
    white-space: nowrap;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr {
    display: inline-block;
    border-left: 1px solid #000;
  }
  .cb-table-both .cb-table-3up.horz > tbody tr:first-child {
    border: 0;
  }
  .cb-table-both .cb-table-3up.horz > thead {
    display: block;
    float: left;
  }
  .cb-table-both .cb-table-3up.horz > thead > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > thead th {
    display: block;
    margin-right: -1px;
    padding: 1px 4px;
    border-width: 0 1px 0 0 !important;
    background: none;
    text-align: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot {
    display: block;
    float: right;
  }
  .cb-table-both .cb-table-3up.horz > tfoot > tr {
    display: block;
  }
  .cb-table-both .cb-table-3up.horz > tfoot th {
    display: block;
    padding: 1px 4px;
    border-width: 0 0 0 1px !important;
    background: none;
  }
  .cb-table-head .vert tr.odd {
    background: rgba(26, 26, 26, 0);
  }
  .cb-table-head .vert td {
    background: none !important;
  }
  .cb-table-column-inactive {
    position: relative;
  }
  .cb-table-column-inactive th {
    max-width: 1em;
    overflow: hidden;
    white-space: nowrap;
  }
  .cb-table-column-inactive > tr:after {
    top: 0;
    position: absolute;
    left: 0;
    text-align: center;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    content: '…';
    display: inline-block;
    background: rgba(0, 0, 0, 0.4);
    color: #fff;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz {
    position: relative;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tbody,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tbody,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    display: block;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot {
    font-size: 0;
    overflow: hidden;
    text-indent: 101%;
    white-space: nowrap;
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz thead tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz thead tr,
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tfoot tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tfoot tr {
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz th,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz th {
    padding: 0;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz tr,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz tr {
    border: 1px solid #000;
    margin-bottom: 4px;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    position: relative;
    padding: 1px 4px;
    display: block;
    border: 0;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after,
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    font-weight: bold;
    position: absolute;
    top: 0;
    overflow: hidden;
    padding: 1px 4px;
    width: 35%;
    border: 0;
    content: attr(data-title);
    text-overflow: ellipsis;
    white-space: nowrap;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(/objects/boxsizing/boxsizing.htc);
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td {
    padding-left: 38%;
  }
  .cb-table-head.cb-table-no-foot .cb-table-3up.horz td:after {
    left: 0;
    padding-right: 1%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td {
    padding-right: 38%;
  }
  .cb-table-foot.cb-table-no-head .cb-table-3up.horz td:after {
    right: 0;
    padding-left: 1%;
  }
  .cb-table-none .cb-table-3up.horz {
    display: block;
    border: 0;
  }
  .cb-table-none .cb-table-3up.horz tbody,
  .cb-table-none .cb-table-3up.horz th,
  .cb-table-none .cb-table-3up.horz tr,
  .cb-table-none .cb-table-3up.horz td {
    display: block;
  }
  .cb-table-none .cb-table-3up.horz tr {
    border: 1px solid #000;
    margin-bottom: 4px;
  }
  .cb-table-none .cb-table-3up.horz td {
    padding: 1px 4px;
    border: 0;
  }
  .cb-table-columns-3 tr {
    min-width: 50%;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
/******* site.less 2013-1-16 *******/
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(60, 0, 15, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  font-size: 14px;
  font-size: 1.4rem;
  width: 100%;
  background-color: transparent;
  color: #000;
  font-family: 'Roboto', helvetica, sans-serif;
  line-height: 1.3;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1280px;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  float: left;
  height: 124px;
  margin-top: 79px;
  margin-bottom: 138px;
  position: relative;
}
@media (max-width: 1023px) {
  #home {
    height: 104px;
    margin-top: 30px;
    margin-bottom: 70px;
  }
}
.logo {
  height: 100%;
  width: auto;
}
.cbdModule--logoByline {
  position: absolute;
  left: 100%;
  top: 0;
  z-index: 2;
  width: auto;
  margin-left: 20px;
}
.cbdModule--logoByline .ns-dynTextfield {
  font-size: 30px;
  line-height: 1;
  font-family: 'EmpiricaHeadlineRegular', serif;
  letter-spacing: 1px;
  width: auto;
  min-width: 80px;
  white-space: nowrap;
  color: #3c000f;
}
@media (max-width: 1023px) {
  .cbdModule--logoByline .ns-dynTextfield {
    font-size: 16px;
  }
}
@media (max-width: 479px) {
  .cbdModule--logoByline {
    display: none;
  }
}
#head {
  float: left;
  width: 100%;
  margin-top: 20px;
}
#view.cb-layout1 #head {
  display: none;
}
.foto .swfi > .fail {
  display: none;
}
.foto .swfi > .fail,
.swfl .chop > p + .file {
  top: 0;
}
.swfl .chop > p {
  margin: 0;
}
#disp.zoom div.head h2 {
  margin-left: 0;
  margin-right: 0;
}
#disp.zoom div.ctrl {
  right: 0;
}
#disp.zoom div.body {
  padding: 0;
  background: none;
  margin-left: 0;
  margin-right: 0;
}
#disp.zoom div.foot {
  background: none;
  margin: 0;
}
#wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
}
#header {
  float: left;
  width: 100%;
  position: relative;
  z-index: 5;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/headerGradient-2024-08-28.jpg);
}
#header .desk {
  position: relative;
}
.headercontent {
  float: left;
  width: 100%;
  position: relative;
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/berge-header-2024-08-26.svg);
}
@media (max-width: 2094px) {
  .headercontent {
    background-position: 0 100%;
  }
}
@media (max-width: 1515px) {
  .headercontent {
    background-size: auto 204px;
  }
}
#topservices {
  float: left;
  width: 100%;
  z-index: 2;
  text-align: center;
  background: #fff;
  border-bottom: 1px solid #cacaca;
  font-size: 0;
  line-height: 0;
}
#topservices .meta {
  color: #666;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 22px 14px;
  display: inline-block;
}
#topservices .meta:hover,
#topservices .meta:focus {
  color: #fff;
  background: #3c000f;
}
.cb-navi-sticky {
  float: left;
  width: 100%;
  margin-top: 61px;
  margin-bottom: -28px;
  display: flex;
  justify-content: center;
}
.cb-navi-sticky.cb-navi-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  margin-top: 0;
  background: #fff;
  border-bottom: 1px solid #cacaca;
  transition: background 0.4s;
}
.cb-navi-sticky.cb-navi-sticky-clone {
  opacity: 0;
}
.navidesk {
  float: left;
  background: #fff;
}
.homelink {
  float: left;
  display: block;
  width: 56px;
  height: 56px;
  background-color: #3c000f;
  background-size: 24px 22px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-house-white.svg);
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
.homelink:hover,
.homelink:focus {
  background-color: #6c2d35;
}
#multimood {
  float: left;
  width: 100%;
  position: relative;
}
#social {
  float: left;
  display: block;
}
#social .meta {
  float: left;
  background-repeat: no-repeat;
  transition: all 0.218s;
  height: 28px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}
#social .meta:hover,
#social .meta:focus {
  opacity: 0.6;
}
#social .service_facebook {
  width: 28px;
  background-image: url(/images/facebook-white.svg);
}
#social .service_youtube {
  margin: 0 20px;
  width: 67px;
  background-image: url(/images/youtube-white.svg);
}
#social .service_instagram {
  width: 28px;
  background-image: url(/images/instagram-white.svg);
}
.searchwidth {
  float: right;
  display: none;
  margin-top: 30px;
}
.searchwidth .meta.auth {
  float: left;
  background: #fff;
  position: relative;
  display: block;
  text-transform: uppercase;
  color: #666;
  font-weight: 500;
  line-height: 38px;
  border: 1px solid #3c000f;
  border-radius: 5px;
  padding: 0 20px;
  margin-left: 20px;
}
.searchwidth .meta.auth:hover,
.searchwidth .meta.auth:focus {
  background: #3c000f;
  color: #fff;
}
#find {
  float: left;
  display: block;
  width: 200px;
}
#find .find {
  display: none;
}
#searchfield {
  float: left;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  color: #666;
  font-weight: 300;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.6) inset;
  border: none;
  background: #fff url(/images/findicon.svg) no-repeat 20px 50%;
  border-radius: 100px;
  padding-left: 46px;
  font-size: 14px;
}
#services {
  float: right;
  margin-top: 10px;
  margin-right: 20px;
  display: block;
}
#services .meta {
  float: left;
  color: #666;
  line-height: 1.15;
  border-right: 1px solid #666;
  padding: 0 8px;
}
#services .meta:hover,
#services .meta:focus {
  color: #3c000f;
}
#services .meta.service_custcontact {
  border: none;
  padding-right: 0;
}
#content {
  float: left;
  width: 100%;
  margin-top: 0px;
}
#mountains {
  float: left;
  width: 100%;
  height: 300px;
  margin-top: 80px;
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/berge-footer-2024-08-26.svg);
}
@media (max-width: 1596px) {
  #mountains {
    background-size: auto 300px;
  }
}
@media (max-width: 1023px) {
  #mountains {
    height: 200px;
    background-size: auto 200px;
  }
}
@media (max-width: 479px) {
  #mountains {
    height: 100px;
    background-size: auto 100px;
  }
}
#maincontent {
  float: left;
  width: 100%;
}
#footer {
  float: left;
  width: 100%;
  background: #3c000f;
  font-size: 14px;
  color: #fff;
  line-height: 1.42;
  padding-bottom: 44px;
}
#footer a {
  color: #fff;
}
#footer a:hover,
#footer a:focus {
  color: #d8cccf;
}
.foottitle {
  float: left;
  width: 100%;
  margin-top: 41px;
  margin-bottom: 22px;
  font-size: 16px;
  font-family: 'EmpiricaHeadlineRegular', serif;
  letter-spacing: 1px;
  font-weight: bold;
}
.footcontent {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footpart {
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
#sponsorenwidth {
  float: left;
  width: 100%;
  border-bottom: 1px solid #fff;
  padding: 19px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
#sponsorenwidth .meta {
  float: left;
  margin: 20px 0;
  display: block;
  width: 168px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
@media (max-width: 479px) {
  #sponsorenwidth {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  #sponsorenwidth .footpart {
    order: 3;
  }
  #sponsorenwidth .footpart.first {
    order: 1;
  }
  #sponsorenwidth .footpart.third {
    order: 2;
  }
}
#sponsorenwidth .service_ermitage {
  height: 31px;
  background-image: url(/images/ermitage-2022-08-31.svg);
}
#sponsorenwidth .service_bombardier {
  height: 23px;
  background-image: url(/images/bombardier.svg);
}
#sponsorenwidth .service_edmond {
  height: 46px;
  background-image: url(/images/edmond-2022-09-02.svg);
}
.service_okgo {
  float: left;
  width: 100%;
  max-width: 230px;
  margin-top: 40px;
  padding-bottom: 60px;
  background-size: 44px 44px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  background-image: url(/images/okgo.svg);
}
.medien {
  float: left;
  width: 100%;
}
#medienlinks {
  float: left;
  display: block;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.legal {
  float: left;
  width: 100%;
  margin-top: 20px;
}
.vcard {
  float: left;
  width: 100%;
}
.vcard .org,
.vcard .adr,
.vcard .postfach,
.vcard .tel {
  display: block;
}
#subnavi {
  float: left;
  width: 17.40740741%;
}
.cb-layout1 #subnavi {
  display: none;
}
.flipppaper {
  height: 800px !important;
}
@media (max-width: 1023px) {
  .flipppaper {
    height: 600px !important;
  }
}
.corona {
  position: absolute;
  left: 0;
  top: 80px;
  width: 100%;
  z-index: 5;
  display: flex;
  justify-content: center;
}
@media (max-width: 1023px) {
  .corona {
    top: 20px;
  }
}
@media (max-width: 479px) {
  .corona {
    float: left;
    position: relative;
    top: 0;
  }
}
.corona__desk {
  float: left;
  width: 1000px;
  max-width: 95%;
}
@media (max-width: 1023px) {
  .corona__desk {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .corona__desk {
    max-width: 92%;
  }
}
.corona .area {
  background-color: rgba(0, 0, 0, 0.57);
  box-sizing: border-box;
  padding: 24px;
  color: #fff;
  width: 100% !important;
  margin: 0 !important;
  font-size: 17px;
  line-height: 1.41176471;
}
#view .corona .area:empty {
  display: none !important;
}
@media (max-width: 479px) {
  .corona .area {
    background-color: transparent;
    color: #000;
    padding: 24px 0 0;
  }
}
.corona .area div.head {
  padding-bottom: 10px;
}
.corona .area div.head h2 {
  color: #fff;
}
@media (max-width: 479px) {
  .corona .area div.head h2 {
    color: #000;
  }
}
.corona .area .unit {
  width: 100% !important;
  margin: 0 !important;
}
.corona .area .unit .part,
.corona .area .unit .head h2 {
  width: 100% !important;
  margin: 0 !important;
}
.corona .area .unit .part.link,
.corona .area .unit .head h2.link {
  margin-top: 20px !important;
}
.langnav {
  float: left;
  margin-left: 20px;
  position: relative;
}
.langnav__toggle {
  float: left;
  color: #3c000f;
  text-transform: uppercase;
  font-weight: bold;
  border-left: 1px solid #3c000f;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  padding: 0 20px 0 20px;
  cursor: pointer;
  background-size: 11px 6px;
  background-position: 100% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/corner-down-maincolor.svg);
}
.langnav__toggle.cb-toggle-active {
  background-image: url(/images/corner-up-maincolor.svg);
}
.langnav__toggle:after {
  content: 'De';
}
body.fr .langnav__toggle:after {
  content: 'Fr';
}
body.en .langnav__toggle:after {
  content: 'En';
}
.langnav div.sub1 {
  float: left;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  display: none;
  flex-direction: column;
  background-color: #fff;
  border-left: 1px solid #fff;
  box-sizing: border-box;
}
.langnav div.sub1 > .item {
  float: left;
}
.langnav div.sub1 > .item.path {
  display: none;
}
.langnav div.sub1 > .item > .menu {
  display: block;
  padding: 11px 0 11px 20px;
  color: #3c000f;
  text-transform: uppercase;
}
.langnav div.sub1 > .item > .menu:hover,
.langnav div.sub1 > .item > .menu:focus {
  background-color: #d8cccf;
}
.langnav.cb-toggle-target-active div.sub1 {
  display: flex;
}
.gmf-partner {
  position: absolute;
  right: 0;
  top: 90px;
  z-index: 3;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .gmf-partner {
    display: none;
  }
}
.gmf-partner__item {
  float: left;
  width: 165px;
  height: 100px;
  margin-top: 10px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-color: rgba(60, 0, 15, 0.35);
}
.gmf-partner__item:first-child {
  margin-top: 0;
}
.gmf-partner__item:hover {
  background-color: rgba(60, 0, 15, 0.5);
}
.gmf-partner__item--menuhin {
  background-image: url(/images/partner-menuhin-2022-09-23.svg);
}
.gmf-partner__item--academy {
  height: 78px;
  background-image: url(/images/partner-academy-2022-09-23.svg);
}
.gmf-partner__item--orchestra {
  background-image: url(/images/partner-orchestra-2022-09-23.svg);
}
.gmf-partner__item--digital {
  background-image: url(/images/partner-digital-2022-09-23.svg);
}
.gmf-partner__item--zelt {
  background-image: url(/images/partner-zelt-2022-09-23.svg);
}
#cb-cookie-warning {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 2000;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(160, 160, 160, 0.4);
  display: flex;
  justify-content: center;
  font-size: 14px;
  line-height: 1.42857143;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
.cb-cookie-warning--container {
  float: left;
  width: 1280px;
  max-width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
@media (max-width: 479px) {
  .cb-cookie-warning--container {
    flex-direction: column;
    padding: 10px 0;
  }
}
.cb-cookie-warning--text {
  float: left;
  max-width: 740px;
}
.cb-cookie-warning--actions {
  float: left;
  margin-left: 40px;
  flex-shrink: 0;
  display: flex;
  gap: 20px;
  flex-direction: row-reverse;
}
@media (max-width: 1023px) {
  .cb-cookie-warning--actions {
    margin-left: 20px;
  }
}
@media (max-width: 479px) {
  .cb-cookie-warning--actions {
    margin-left: 0;
    margin-top: 10px;
  }
}
.cb-cookie-warning__button {
  background: #3c000f;
  display: inline-block;
  border-radius: 12px;
  line-height: 1.5;
  color: #fff;
  padding: 9px 20px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Roboto', helvetica, sans-serif;
  border: none;
  min-height: 0;
  text-shadow: none;
  cursor: pointer;
}
.cb-cookie-warning__button:hover,
.cb-cookie-warning__button:focus {
  background: #6c2d35;
  color: #fff;
}
.moodTitles {
  position: absolute;
  left: 40px;
  bottom: 90px;
  z-index: 4;
  background-color: rgba(60, 0, 15, 0.35);
  width: 460px;
  box-sizing: border-box;
  padding: 16px 10px 20px;
  text-align: center;
  transition: all 0.4s;
}
@media (min-width: 480px) {
  .moodTitles.is-hidden {
    opacity: 0;
    pointer-events: none;
  }
}
@media (max-width: 1023px) {
  .moodTitles {
    width: 360px;
    left: 20px;
    bottom: 20px;
  }
}
@media (max-width: 479px) {
  .moodTitles {
    position: static;
    float: left;
    width: 100%;
    padding: 20px 4%;
    background-color: #3c000f;
  }
}
.cbdModule--moodTitle .cbDynamicContent__element {
  color: #fff;
  font-family: 'EmpiricaHeadlineRegular', serif;
  font-weight: normal;
  text-transform: none;
  font-size: 58px;
  line-height: 0.86206897;
}
@media (max-width: 1023px) {
  .cbdModule--moodTitle .cbDynamicContent__element {
    font-size: 48px;
    line-height: 1;
  }
}
.cbdModule--moodSubTitle {
  margin-top: 14px;
}
.cbdModule--moodSubTitle .cbDynamicContent__element {
  color: #fff;
  font-family: 'EmpiricaHeadlineRegular', serif;
  font-weight: normal;
  text-transform: none;
  font-size: 20px;
  line-height: 1.4;
  padding: 0;
}
@media (max-width: 1023px) {
  .cbdModule--moodSubTitle {
    margin-top: 4px;
  }
  .cbdModule--moodSubTitle .cbDynamicContent__element {
    font-size: 15px;
    line-height: 1.33333333;
  }
}
.cbdModule--moodSubSubTitle {
  margin-top: 10px;
}
.cbdModule--moodSubSubTitle .cbDynamicContent__element {
  color: #fff;
  font-family: 'EmpiricaHeadlineRegular', serif;
  font-weight: normal;
  text-transform: none;
  font-size: 23px;
  line-height: 1.13043478;
  text-transform: uppercase;
}
@media (max-width: 1023px) {
  .cbdModule--moodSubSubTitle {
    margin-top: 6px;
  }
  .cbdModule--moodSubSubTitle .cbDynamicContent__element {
    font-size: 18px;
    line-height: 1.22222222;
  }
}
.mission {
  position: absolute;
  right: 320px;
  top: 30px;
  z-index: 2;
  width: 180px;
}
@media (max-width: 1023px) {
  .mission {
    right: 2.5%;
    top: initial;
    bottom: 20px;
    width: 130px;
  }
}
@media (max-width: 479px) {
  .mission {
    width: 100px;
  }
}
.mission .meta {
  float: left;
  width: 100%;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  height: 0;
  padding-bottom: 100%;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/mission-de-2024-08-26.svg);
}
.fr .mission .meta {
  background-image: url(/images/mission-fr-2024-08-26.svg);
}
.en .mission .meta {
  background-image: url(/images/mission-en-2024-08-26.svg);
}
@media (max-width: 1023px) {
  .mission .meta {
    background-image: url(/images/mission-mobile-2024-08-26.svg) !important;
  }
}
h1 {
  font-size: 18px;
  line-height: 1.4;
  font-family: 'EmpiricaHeadlineRegular', serif;
  color: #3c000f;
  text-transform: uppercase;
  letter-spacing: 1px;
}
h2,
h5 {
  font-size: 18px;
  line-height: 1.4;
  font-family: 'EmpiricaHeadlineRegular', serif;
  color: #3c000f;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  letter-spacing: 1px;
}
h2 i,
h5 i {
  color: #000;
  text-transform: none;
  font-size: 14px;
  line-height: 1.3;
  font-family: 'Roboto', helvetica, sans-serif;
}
h3 {
  font-size: 18px;
  line-height: 1.2;
  font-family: 'EmpiricaHeadlineRegular', serif;
  color: #3c000f;
  text-transform: uppercase;
  padding-top: 12px;
  padding-bottom: 5px;
  letter-spacing: 1px;
}
.cb-layout1 div.main div.unit h3 {
  color: #000;
}
h4 {
  font-size: 1em;
  color: #3c000f;
  font-weight: bold;
}
.loud {
  padding: 0.2em 0.4em;
  background-color: #bfbfbf;
  color: #fff;
  /* redo with @basecolor & guard */
}
.loud > .open {
  color: #e6e6e6;
  /* redo with @basecolor */
}
.loud > .open:hover,
.loud > .open:focus {
  color: #bfbfbf;
}
.pale {
  color: #4d4d4d;
}
.skew {
  letter-spacing: 0.03em;
  font-style: italic;
  font-family: georgia, serif;
}
.text {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
.gist,
.dent {
  list-style-type: none;
  margin-left: 0;
  background: url(/images/list.png) no-repeat 0 0.6em;
  padding-left: 12px;
}
.dent {
  margin-left: 18px;
}
a {
  color: #3c000f;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #6c2d35;
}
.part.text a.open {
  text-decoration: underline;
  color: #000;
}
.part.text a.open:hover,
.part.text a.open:focus {
  text-decoration: none;
  color: #000;
}
.link {
  background: url('/icon-link/link.gif') no-repeat 0 0.4em;
}
.link.mail {
  background-image: url('/icon-link/mail.gif');
  background-position: 0 0.5em;
}
.load {
  background-position: 0 50%;
}
.rss {
  background-image: url('/icon-link/rss_small.gif');
}
.load > .load {
  margin-left: 30px;
  padding: 7px 0 3px;
}
div.link {
  background: none !important;
  margin-top: 10px;
}
div.link a.open {
  margin-left: 0;
  background: #fff;
  display: inline-block;
  border-radius: 12px;
  line-height: 1.5;
  padding: 9px 20px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Roboto', helvetica, sans-serif;
  color: #3c000f;
}
div.link a.open:hover,
div.link a.open:focus {
  background: #3c000f;
  color: #fff;
}
div.link a.open:active {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6) inset;
}
.edge div.link a.open {
  background: #3c000f;
  color: #fff;
}
.edge div.link a.open:hover,
.edge div.link a.open:focus {
  background: #fff;
  color: #3c000f;
}
.cb-layout5 div.link a.open,
.cb-layout4 div.link a.open,
.cb-layout2 div.link a.open,
.cb-layout3 div.link a.open,
.corona .area div.link a.open {
  background: #3c000f;
  color: #fff;
}
.cb-layout5 div.link a.open:hover,
.cb-layout4 div.link a.open:hover,
.cb-layout2 div.link a.open:hover,
.cb-layout3 div.link a.open:hover,
.corona .area div.link a.open:hover,
.cb-layout5 div.link a.open:focus,
.cb-layout4 div.link a.open:focus,
.cb-layout2 div.link a.open:focus,
.cb-layout3 div.link a.open:focus,
.corona .area div.link a.open:focus {
  background: #6c2d35;
}
.cb-layout5 div.main,
.cb-layout4 div.main,
.cb-layout2 div.main,
.cb-layout3 div.main {
  display: flex;
  flex-wrap: wrap;
}
.cb-layout1 div.main {
  display: flex;
  flex-wrap: wrap;
}
.cb-layout1 div.main div.unit.slim {
  display: flex;
  flex-direction: column;
}
.cb-layout1 div.main div.unit.slim div.body {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}
.cb-layout1 div.main div.unit.slim div.body div.part {
  flex: 0 0 auto;
}
.cb-layout1 div.main div.unit.slim div.body div.part.text {
  flex: 1 0 auto;
}
.cb-layout1 div.main div.unit {
  padding-bottom: 12px;
}
.cb-layout1 div.main div.unit.pure {
  background: #e6d3c5;
}
.cb-layout1 div.main div.unit.seam {
  background: #ebdfe1;
}
.cb-layout1 div.main div.unit.flat {
  background: #e6e6e6;
}
.cb-layout1 div.main div.unit div.pict {
  width: 100% !important;
  margin: 0 !important;
}
.cb-layout1 div.main div.unit.edge {
  display: block;
  padding-bottom: 0;
  font-family: 'EmpiricaHeadlineRegular', serif;
  font-size: 18px;
  line-height: 1.2;
  text-transform: uppercase;
  letter-spacing: 1px;
}
#view.cb-layout1 div.main div.unit.edge {
  background: #e6e6e6;
}
#view.cb-layout1 div.main div.unit.edge div.part {
  margin-top: 0;
  margin-bottom: 0;
}
#view.cb-layout1 div.main div.unit.edge div.link {
  position: absolute;
  bottom: 18px;
  left: 0;
}
#view.cb-layout1 div.main div.unit.edge div.text {
  position: absolute;
  bottom: 67px;
  left: 0;
  color: #fff;
}
div.base {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
}
@media (max-width: 479px) {
  div.base {
    margin-top: 40px;
  }
}
.north {
  position: absolute;
  right: 90px;
  bottom: 90px;
  z-index: 2;
  width: 560px !important;
}
#edit .north {
  min-height: 100px;
}
@media (max-width: 1439px) {
  .north {
    right: 200px;
    width: 350px !important;
  }
}
@media (max-width: 1023px) {
  .north {
    right: 20px;
    bottom: initial;
    top: 20px;
    width: calc(100% - 40px) !important;
  }
}
@media (max-width: 479px) {
  .north {
    top: calc((100vw/1920*992) - 14px);
    transform: translateY(-100%);
  }
}
.cb-layout5 .main,
.cb-layout4 .main,
.cb-layout2 .main,
.cb-layout3 .main {
  display: flex;
  flex-wrap: wrap;
}
.cb-layout5 .main .flat,
.cb-layout4 .main .flat,
.cb-layout2 .main .flat,
.cb-layout3 .main .flat {
  background-color: #d8cccf;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1023px) {
  .cb-layout5 .main .flat,
  .cb-layout4 .main .flat,
  .cb-layout2 .main .flat,
  .cb-layout3 .main .flat {
    padding: 5px 15px;
  }
}
.cb-layout5 .main .flat .head,
.cb-layout4 .main .flat .head,
.cb-layout2 .main .flat .head,
.cb-layout3 .main .flat .head,
.cb-layout5 .main .flat .foot,
.cb-layout4 .main .flat .foot,
.cb-layout2 .main .flat .foot,
.cb-layout3 .main .flat .foot {
  float: left;
  width: 100%;
  flex-grow: 0;
}
.cb-layout5 .main .flat .body,
.cb-layout4 .main .flat .body,
.cb-layout2 .main .flat .body,
.cb-layout3 .main .flat .body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.cb-layout5 .main .flat .part,
.cb-layout4 .main .flat .part,
.cb-layout2 .main .flat .part,
.cb-layout3 .main .flat .part,
.cb-layout5 .main .flat .head h2,
.cb-layout4 .main .flat .head h2,
.cb-layout2 .main .flat .head h2,
.cb-layout3 .main .flat .head h2 {
  float: left;
  width: 100% !important;
  margin: 10px 0 !important;
}
.cb-layout5 .main .flat .part:last-child,
.cb-layout4 .main .flat .part:last-child,
.cb-layout2 .main .flat .part:last-child,
.cb-layout3 .main .flat .part:last-child {
  margin-top: auto !important;
  padding-top: 10px;
}
div.base div.seam.wide .link.tiny {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 479px) {
  div.base div.seam.wide .link a.open {
    width: 100%;
    box-sizing: border-box;
  }
}
div.north .unit {
  margin: 0 !important;
  width: 100% !important;
  text-align: right;
  color: #3c000f;
  font-size: 24px;
  line-height: 1.58333333;
  color: #fff;
}
div.north .unit div.part,
div.north .unit div.head h2 {
  margin: 0 !important;
  width: 100% !important;
}
div.north .unit h2 {
  font-size: 44px;
  line-height: 1.36363636;
  text-transform: none;
  color: #fff;
}
@media (max-width: 1439px) {
  div.north .unit {
    font-size: 18px;
    line-height: 1.55555556;
  }
  div.north .unit h2 {
    font-size: 34px;
    line-height: 1.29411765;
  }
}
@media (max-width: 1023px) {
  div.north .unit {
    font-size: 14px;
    line-height: 1.71428571;
  }
  div.north .unit h2 {
    font-size: 22px;
    line-height: 1.27272727;
  }
}
@media (max-width: 479px) {
  div.north .unit h2 {
    font-size: 18px;
    line-height: 1.44444444;
  }
}
div.north .unit.seam {
  color: #3c000f;
}
div.north .unit.seam h2 {
  color: #3c000f;
}
div.fold a.link {
  display: block;
  color: #3c000f;
  font-weight: 500;
  border-bottom: 1px solid #999;
  padding-bottom: 10px;
}
div.fold a.link:hover,
div.fold a.link:focus {
  color: #3c000f;
}
div.fold a.link.less {
  color: #3c000f;
}
div.fold a.link.less:hover,
div.fold a.link.less:focus {
  color: #3c000f;
}
div.form input.text,
div.form textarea {
  padding: 5px 3px;
}
div.form input.submit {
  float: left;
  background: #3c000f;
  display: inline-block;
  border-radius: 12px;
  line-height: 1.5;
  margin-top: 0.4em;
  color: #fff;
  padding: 9px 20px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Roboto', helvetica, sans-serif;
  border: none;
  min-height: 0;
  text-shadow: none;
}
div.form input.submit:hover,
div.form input.submit:focus {
  background: #3c000f;
  color: #fff;
}
div.form input.submit:active {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6) inset;
}
div.form .recaptcha-info {
  text-align: left;
}
div.load {
  background: none !important;
}
div.load a.load {
  margin-left: 0;
  padding: 0 0 0 26px;
  font-size: 14px;
  line-height: 1.42857143;
  background-size: 20px 20px;
  background-position: 0 0;
  background-repeat: no-repeat;
  background-image: url(/images/icon-load-maincolor.svg);
}
.cb-image-figure {
  float: left;
  width: 100%;
  margin: 0;
}
.cb-image-container {
  float: left;
  width: 100%;
}
.cb-image-container img {
  float: left;
  width: 100%;
  height: auto;
}
.cb-image-caption {
  float: left;
  width: 100%;
  margin-top: 4px;
  color: #000;
}
div.pict a {
  float: left;
  width: 100%;
}
.cb-layout4 .main .unit.slim .pict.tiny .cb-image-figure,
.cb-layout2 .main .unit.slim .pict.tiny .cb-image-figure,
.cb-layout3 .main .unit.slim .pict.tiny .cb-image-figure {
  display: flex;
  flex-direction: column;
}
.cb-layout4 .main .unit.slim .pict.tiny .cb-image-figure .cb-image-container,
.cb-layout2 .main .unit.slim .pict.tiny .cb-image-figure .cb-image-container,
.cb-layout3 .main .unit.slim .pict.tiny .cb-image-figure .cb-image-container {
  order: 2;
}
.cb-layout4 .main .unit.slim .pict.tiny .cb-image-figure .cb-image-caption,
.cb-layout2 .main .unit.slim .pict.tiny .cb-image-figure .cb-image-caption,
.cb-layout3 .main .unit.slim .pict.tiny .cb-image-figure .cb-image-caption {
  order: 1;
  margin-top: 0;
  margin-bottom: 4px;
}
.grow {
  text-transform: none !important;
}
/* MOOD CONTAINER */
#expo {
  float: left;
  width: 100%;
  height: 0;
  padding-bottom: 51.66666667%;
  position: relative;
}
/* IMAGE CONTAINER */
#slides {
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
/* MOOD NAVIGATION */
#expo div.link {
  display: none;
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.3em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Roboto', helvetica, sans-serif;
  font-size: 14px;
  font-size: 1.4rem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.3em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 14px;
  font-size: 1.4rem;
  color: #444;
  font-weight: normal;
  font-family: 'Roboto', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  -webkit-box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  -webkit-transition: -webkit-box-shadow 0.218s;
  -moz-transition: box-shadow 0.218s;
  -o-transition: box-shadow 0.218s;
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  font-size: 14px;
  font-size: 1.4rem;
  display: inline-block;
  padding: 0.1em 1em;
  min-height: 1.3em;
  border: 1px solid;
  border-color: #230009 #000000 #000000 #230009;
  border-radius: 2px;
  background-color: #3c000f;
  color: #fff;
  text-align: center;
  text-decoration: none;
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.75);
  line-height: 1.3em;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  border-color: #230009 #000000 #000000 #230009;
  background-color: #4b0013;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  border-color: #230009 #000000 #000000 #230009;
  background-color: #090002;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1) inset;
  color: #fff;
  text-shadow: none;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.3em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi,
#find,
.dock {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 250px;
  height: 100%;
  z-index: 2100;
  overflow: scroll;
  background: #fff;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
#mobservices {
  float: left;
  width: 100%;
  display: block;
  background: #3c000f;
  padding: 15px 0;
}
#mobservices .meta {
  color: #fff;
  display: block;
  font-size: 16px;
  line-height: 1.5;
  padding: 0 20px;
}
#mobservices .meta.service_gstaadmenuhinfestival {
  background: url(/images/mobservices.svg) no-repeat 0 50%;
}
#mobservices2 {
  float: left;
  width: 100%;
  display: block;
  margin-top: 22px;
}
#mobservices2 .meta {
  display: block;
  padding: 0 20px;
  font-size: 18px;
  color: #000;
  line-height: 1.8;
}
#social2 {
  float: left;
  margin-left: 20px;
  display: block;
  margin-top: 27px;
}
#social2 .meta {
  float: left;
  font-size: 18px;
  color: #000;
  line-height: 1.2;
  border-right: 1px solid #000;
  padding: 0 8px;
}
#social2 .meta.service_facebook {
  padding-left: 0;
}
#social2 .meta.service_youtube {
  border: none;
  padding-right: 0;
}
#mobsearch {
  float: left;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  display: block;
  margin-top: 27px;
}
#mobsearch .meta {
  float: left;
  font-size: 18px;
  color: #000;
  line-height: 1.2;
  border-right: 1px solid #000;
  padding: 0 8px;
}
#mobsearch .meta:first-child {
  padding-left: 0;
}
#mobsearch .meta:last-child {
  border-right: none;
  padding-right: 0;
}
.togglenavigation {
  position: fixed;
  top: 15px;
  display: block;
  width: 28px;
  height: 20px;
  z-index: 2100;
  cursor: pointer;
  color: #6c2d35 !important;
  -webkit-tap-highlight-color: transparent;
  -o-transition: all 0.3s ease-in;
  -moz-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  -webkit-transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  padding: 5px;
  background: #fff;
}
.togglenavigation:active > span {
  background: #4f2127 !important;
}
.togglenavigation > span {
  left: 5px;
  top: 50%;
  position: absolute;
  display: block;
  height: 4px;
  width: 28px;
  margin-top: -2px;
  background: currentColor;
}
.togglenavigation > .tline-1 {
  margin-top: -10px;
}
.togglenavigation > .tline-4 {
  margin-top: 6px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  -webkit-transition: -webkit-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -o-transition: -o-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  -moz-transition: -moz-transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  -o-transition: all 0.218s ease-out;
  -moz-transition: all 0.218s ease-out;
  -webkit-transition: all 0.218s ease-out;
  transition: all 0.218s ease-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
body.cb-toggle-target-active .togglenavigation {
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  -webkit-transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
/* RIGHT */
#mobile-navi {
  right: -250px;
}
.togglenavigation {
  right: 4%;
}
body.cb-toggle-target-active #mobile-navi {
  right: 0;
}
body.cb-toggle-target-active .togglenavigation {
  margin-right: 250px;
}
/* LEFT */
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item .menu {
  font-weight: 500;
  display: block;
  color: #000;
  padding: 5px 20px;
  padding-right: 33px;
  font-size: 18px;
}
#mobile-navi .navi .item .menu.path {
  color: #6c2d35;
}
#mobile-navi .navi.sub1 {
  margin-left: 20px;
  margin-top: 32px;
  margin-bottom: 30px;
  width: auto;
}
#mobile-navi .navi.sub1 .item {
  width: auto;
  border-right: 1px solid #6c2d35;
  padding: 0 8px;
}
#mobile-navi .navi.sub1 .item.init {
  padding-left: 0;
}
#mobile-navi .navi.sub1 .item.exit {
  padding-right: 0;
  border: none;
}
#mobile-navi .navi.sub1 .item .menu {
  padding: 0;
  color: #6c2d35;
  font-weight: 300;
  text-transform: uppercase;
}
#mobile-navi .navi.sub1 .item .menu.path {
  font-weight: 300;
  color: #6c2d35;
}
#mobile-navi .navi.sub2 {
  margin-top: 21px;
}
#mobile-navi .navi.sub3 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub3 .item.exit {
  margin-bottom: 15px;
}
#mobile-navi .navi.sub3 .item .menu {
  font-weight: 300;
  padding-left: 40px;
  padding-right: 20px;
}
#mobile-navi .navi.sub4 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub4 .item .menu {
  padding-right: 20px;
  padding-left: 50px;
  font-size: 14px;
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  -o-transition: all 2s;
  -moz-transition: all 2s;
  -webkit-transition: all 2s;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  width: 33px;
  height: 33px;
}
#mobile-navi .cb-toggle:after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 33px;
  height: 33px;
  background: url(/images/mnavi.svg) no-repeat 50% 50%;
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active:after {
  -moz-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  -o-transition: all 0.218s;
  -moz-transition: all 0.218s;
  -webkit-transition: all 0.218s;
  transition: all 0.218s;
}
#mobile-navi div.sub2 .cb-toggle,
#mobile-navi div.sub3 .cb-toggle {
  height: 33px;
  width: 100%;
  background-position: right 10px center;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
/******* layout-small.less 2013-1-16 *******/
.desk {
  max-width: 92%;
}
html.ie10 #home {
  height: 103px;
  background: url(/images/logo2.png) no-repeat 0 0;
  background-size: 174px 103px;
}
html.ie10 #home img {
  display: none;
}
#services {
  margin-top: 5px;
}
#services .meta {
  font-size: 12px;
  line-height: 16px;
}
#services .meta.auth {
  display: none;
}
.cb-navi-sticky {
  display: none;
}
@media only screen and (max-width: 479px) {
  .vcard {
    margin-top: 0;
  }
  #social .meta {
    width: 25px;
    height: 25px;
  }
  #social .meta.service_youtube {
    width: 60px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 5%;
  margin-left: 5%;
}
.area .part,
.area > .grid table {
  margin-right: 5%;
  margin-left: 5%;
  width: 90%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 90%;
}
.cb-layout5 .main,
.cb-layout4 .main,
.cb-layout2 .main,
.cb-layout3 .main {
  width: 100%;
}
.cb-layout5 .main > .unit,
.cb-layout4 .main > .unit,
.cb-layout2 .main > .unit,
.cb-layout3 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout5 .main h2,
.cb-layout4 .main h2,
.cb-layout2 .main h2,
.cb-layout3 .main h2,
.cb-layout5 .main .foot,
.cb-layout4 .main .foot,
.cb-layout2 .main .foot,
.cb-layout3 .main .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.cb-layout5 .main .part,
.cb-layout4 .main .part,
.cb-layout2 .main .part,
.cb-layout3 .main .part,
.cb-layout5 .main > .grid table,
.cb-layout4 .main > .grid table,
.cb-layout2 .main > .grid table,
.cb-layout3 .main > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout5 .main > .slim .part,
.cb-layout4 .main > .slim .part,
.cb-layout2 .main > .slim .part,
.cb-layout3 .main > .slim .part,
.cb-layout5 .main > .slim.grid table,
.cb-layout4 .main > .slim.grid table,
.cb-layout2 .main > .slim.grid table,
.cb-layout3 .main > .slim.grid table {
  width: 100%;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.base .part,
.base > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 100%;
}
@media (max-width: 479px) {
  .cb-layout5 .main .unit.slim .part.tiny + .part.tiny,
  .cb-layout4 .main .unit.slim .part.tiny + .part.tiny,
  .cb-layout2 .main .unit.slim .part.tiny + .part.tiny,
  .cb-layout3 .main .unit.slim .part.tiny + .part.tiny {
    margin-top: 41px;
  }
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
.eventLists {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.eventList__title {
  margin-bottom: 20px;
}
#view .eventlistSettings {
  display: none;
}
.eventlist {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.event {
  width: 100%;
}
.event--teaser {
  display: grid;
  grid-template-columns: 1fr 100px;
  gap: 0 20px;
}
.event--teaser:nth-child(odd) {
  background-color: #d8cccf;
}
.event--teaser:nth-child(even) {
  background-color: #ebdfe1;
}
@media (max-width: 768px) {
  .event--teaser {
    grid-template-columns: 1fr;
  }
}
.event--teaser .event__content {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 30px;
  padding: 30px 20px;
  box-sizing: border-box;
}
@media (max-width: 608px) {
  .event--teaser .event__content {
    grid-template-columns: 150px 1fr;
  }
}
@media (max-width: 479px) {
  .event--teaser .event__content {
    grid-template-columns: 1fr;
  }
}
.event--teaser .event__infos {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.event--teaser .event__image {
  display: flex;
  flex-direction: column;
}
.event--teaser .event__part--image {
  width: 100%;
  aspect-ratio: 4 / 3;
  object-fit: cover;
  object-position: center;
}
.event--teaser .event__titles h3 {
  font-family: 'Roboto', helvetica, sans-serif;
  text-transform: unset;
  letter-spacing: unset;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  padding: 0;
}
.event--teaser .event__titles h2 {
  font-family: 'Roboto', helvetica, sans-serif;
  text-transform: unset;
  letter-spacing: unset;
  font-size: 18px;
  line-height: 1.2;
  color: #000;
  padding: 0;
}
.event--teaser .event__tickets {
  background-color: #3c000f;
  color: #fff;
  display: flex;
  flex-direction: column;
  min-height: 80px;
}
@media (max-width: 768px) {
  .event--teaser .event__tickets {
    flex-direction: row;
  }
}
.event--teaser .event__part--ticketLink {
  color: #fff;
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  transition: all 0.4s;
  padding: 10px;
  box-sizing: border-box;
  font-weight: bold;
}
.event--teaser .event__part--ticketLink:not(:last-child) {
  border-bottom: 2px solid #fff;
}
@media (max-width: 768px) {
  .event--teaser .event__part--ticketLink:not(:last-child) {
    border-bottom: unset;
    border-right: 2px solid #fff;
  }
}
.event--teaser .event__part--ticketLink:hover,
.event--teaser .event__part--ticketLink:focus {
  background-color: #6c2d35;
}
.event--teaser .event__part--link {
  font-weight: bold;
  font-size: 16px;
  text-decoration: underline;
  padding-left: 12px;
  position: relative;
}
.event--teaser .event__part--link:before {
  content: '»';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
}
#view .eventlistSettings {
  display: none;
}
.django_debug {
  display: none;
}
#view.cb-layout5 .main {
  display: none;
}
.eventlist {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.eventMood {
  aspect-ratio: 1920/992;
  position: relative;
  isolation: isolate;
  background-color: #6c2d35;
}
.eventMood__overlay {
  position: absolute;
  right: 90px;
  bottom: 90px;
  z-index: 2;
  width: 560px !important;
  text-align: right;
  color: #3c000f;
  font-size: 24px;
  line-height: 1.58333333;
  color: #fff;
}
@media (max-width: 1439px) {
  .eventMood__overlay {
    font-size: 18px;
    line-height: 1.55555556;
  }
  .eventMood__overlay h2 {
    font-size: 34px;
    line-height: 1.29411765;
  }
}
#edit .eventMood__overlay {
  min-height: 100px;
}
@media (max-width: 1439px) {
  .eventMood__overlay {
    right: 200px;
    width: 350px !important;
  }
}
@media (max-width: 1023px) {
  .eventMood__overlay {
    right: 20px;
    bottom: initial;
    top: 20px;
    width: calc(100% - 40px) !important;
  }
}
@media (max-width: 479px) {
  .eventMood__overlay {
    top: unset;
    bottom: 20px;
  }
}
.eventMood__container {
  width: 100%;
  height: 100%;
}
.eventMood__container:has(.eventMood__image:nth-child(1)) .eventMood__image {
  animation-name: none;
}
.eventMood__container:has(.eventMood__image:nth-child(2)) .eventMood__image {
  animation-name: fade;
  --imageFadeCount: 2;
  --imageFadeDuration: 7s;
}
.eventMood__container:has(.eventMood__image:nth-child(3)) .eventMood__image {
  animation-name: fade;
  --imageFadeCount: 3;
  --imageFadeDuration: 7s;
}
.eventMood__container:has(.eventMood__image:nth-child(4)) .eventMood__image {
  animation-name: fade;
  --imageFadeCount: 4;
  --imageFadeDuration: 7s;
}
.eventMood__image {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  animation-iteration-count: infinite;
  animation-duration: calc(var(--imageFadeDuration) * var(--imageFadeCount));
  animation-timing-function: linear;
}
.eventMood__image:nth-child(1) {
  animation-delay: calc(var(--imageFadeDuration) * (var(--imageFadeCount) - 1) * -1);
}
.eventMood__image:nth-child(2) {
  animation-delay: calc(var(--imageFadeDuration) * (var(--imageFadeCount) - 2) * -1);
}
.eventMood__image:nth-child(3) {
  animation-delay: calc(var(--imageFadeDuration) * (var(--imageFadeCount) - 3) * -1);
}
.eventMood__image:nth-child(4) {
  animation-delay: calc(var(--imageFadeDuration) * (var(--imageFadeCount) - 4) * -1);
}
.eventMood__title {
  font-size: 44px;
  line-height: 1.36363636;
  text-transform: none;
  color: #fff;
  font-family: 'EmpiricaHeadlineRegular', serif;
  font-weight: normal;
}
@media (max-width: 479px) {
  .eventMood__title {
    font-size: 30px;
    line-height: 1.2;
  }
}
@keyframes fade {
  25%,
  75% {
    opacity: 0;
  }
}
.event--detail {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
}
.event--detail .event__content {
  display: grid;
  grid-template-columns: 1fr 350px;
  gap: 30px;
}
@media (max-width: 1023px) {
  .event--detail .event__content {
    grid-template-columns: 1fr;
  }
}
.event--detail .event__program {
  display: grid;
}
.event--detail .event__section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.event--detail .event__titles h3 {
  font-family: 'Roboto', helvetica, sans-serif;
  text-transform: unset;
  letter-spacing: unset;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;
  color: #000;
  padding: 0;
}
.event--detail .event__part {
  display: flex;
  flex-direction: column;
}
.event--detail .event__part--ticketLink {
  margin-left: 0;
  background: #3c000f;
  color: #fff;
  display: inline-block;
  border-radius: 12px;
  line-height: 1.5;
  padding: 9px 20px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Roboto', helvetica, sans-serif;
}
.event--detail .event__part--ticketLink:hover,
.event--detail .event__part--ticketLink:focus {
  background: #6c2d35;
  color: #fff;
}
.event--detail .event__part--ticketLink:active {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6) inset;
}
.event--detail .event__transfer {
  padding-top: 30px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.event--detail .event__transfer:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-bus.svg);
}
.event--detail .event__part--transferLink {
  text-decoration: underline;
  padding-left: 12px;
  box-sizing: border-box;
  position: relative;
}
.event--detail .event__part--transferLink:before {
  content: '»';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
}
.event--detail .event__artists {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.event--detail .event__part--artistName {
  font-size: 18px;
  line-height: 1.4;
  font-family: 'EmpiricaHeadlineRegular', serif;
  color: #3c000f;
  text-transform: uppercase;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  letter-spacing: 1px;
  display: block;
}
.event--detail .event__part--artistName .instrument {
  font-family: 'Roboto', helvetica, sans-serif;
  color: #000;
  text-transform: none;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: none;
  font-weight: bold;
  font-style: italic;
}
.event--detail .event__part--artistAwards li {
  list-style: none;
  font-family: 'Roboto', helvetica, sans-serif;
  color: #000;
  text-transform: none;
  font-size: 14px;
  line-height: 1.3;
  letter-spacing: none;
  font-weight: bold;
  font-style: italic;
}
.folding {
  user-select: none;
  border-bottom: 1px solid #000;
}
.folding:nth-child(1 of .folding) {
  margin-top: 10px;
  border-top: 1px solid #000;
}
.folding > .folding__less {
  font-weight: bold;
  padding: 10px 0;
  box-sizing: border-box;
  color: #3c000f;
  justify-content: space-between;
  display: flex;
  cursor: pointer;
}
.folding > .folding__less:after {
  content: '';
  display: block;
  position: relative;
  width: 15px;
  height: 14px;
  transition: all 0.3s;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/folding-arrow.svg);
}
.folding[open] .folding__less:after {
  transform: rotate(180deg);
}
.folding__less::-webkit-details-marker {
  display: none;
}
.folding__more {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding-bottom: 10px;
}
.event__pager {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 30px;
  margin-top: 30px;
}
@media (max-width: 479px) {
  .event__pager {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.event__pagerLink {
  margin-left: 0;
  background: #3c000f;
  color: #fff;
  display: inline-block;
  border-radius: 12px;
  line-height: 1.5;
  padding: 9px 20px;
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Roboto', helvetica, sans-serif;
}
.event__pagerLink--next {
  grid-column: 3;
}
.event__pagerLink--previous {
  grid-column: 1;
}
.event__pagerLink:hover,
.event__pagerLink:focus {
  background: #6c2d35;
  color: #fff;
}
.event__pagerLink:active {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.6) inset;
}
/* scan.less 2013-1-16 */
.scan .cb-hybrid {
  color: #000;
  text-decoration: none;
}
.scan .cb-hybrid:hover,
.scan .cb-hybrid:focus {
  color: #000000;
}
.swap {
  text-align: center;
}
.scan form,
.scan > table,
.scan fieldset {
  width: 100% !important;
}
.scan > table {
  margin: 0 !important;
}
.swap td > span,
.swap td > .cb-hybrid {
  display: block;
}
td.prev,
td.next {
  color: rgba(0, 0, 0, 0.3);
}
.unit.cb-batch .same {
  background-color: #090002;
  color: #000;
}
/******* module-album.less 2013-1-16 *******/
.body-mobile img,
noscript img {
  /* resize - noscript mode must still work */
  width: 100%;
}
img.same {
  background: none !important;
}
div.foto div.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.cb-mobile .cb-slideshow > .head > .ctrl {
  display: none;
}
.cb-slideshow > .body-mobile img.zoom {
  top: 100% !important;
  left: 100% !important;
  display: block !important;
  margin: -20px 0 0 -20px !important;
  width: 17px !important;
  height: 17px;
  opacity: 0.7;
}
div.foto,
.crossslideContainer {
  position: relative;
}
.crossslide,
.cb-slideshow-images img {
  width: 100%;
}
.cb-strips .foto > .cb-landscape,
.cb-matrix .body-mobile .foto > img,
.cb-contentflow .foto > img,
.cb-slideshow .foto > img,
.body-non-mobile .foto > img {
  margin: 0 auto;
  width: 100%;
}
.cb-strips .foto > .cb-portrait {
  height: 100%;
  width: auto;
}
.body-mobile a.foto,
.body-mobile span.foto {
  width: 100%;
}
noscript a.foto {
  position: relative !important;
}
.body-mobile .cb-matrix .foto,
.body-mobile .cb-contentflow .foto {
  position: relative;
}
.part.desc {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.same.norm,
.same.foto {
  background: none;
}
.body-non-mobile a.foto,
.body-non-mobile span.foto {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  padding: 0;
  width: 100%;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  width: 18px;
}
.prev > .prev,
.next > .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  display: block;
  height: 18px;
}
.cb-strips .prev,
.cb-strips .next,
.cb-slideshow .prev > .cb-hybrid,
.cb-slideshow .next > .cb-hybrid {
  background-image: url('/icons/prev_next.png');
  background-repeat: no-repeat;
}
.cb-slideshow .ctrl > .prev,
.cb-slideshow .ctrl > .next {
  float: left;
}
.cb-album .prev {
  background-position: 0px -54px;
}
.cb-strips td.prev,
.cb-strips td.next {
  background: none;
  vertical-align: middle;
}
.cb-album a.prev,
.cb-album .prev > .cb-hybrid {
  background-position: 0px 0px;
}
.cb-album a.prev:hover,
.cb-album .prev > .cb-hybrid:hover {
  background-position: 0px -18px;
}
.cb-album a.prev:active,
.cb-album .prev > .cb-hybrid:active {
  background-position: 0px -36px;
}
.cb-album .prev > .cb-hybrid.fade {
  background-position: 0px -54px;
}
.cb-album .next {
  background-position: -17px -54px;
}
.cb-album a.next,
.cb-album .next > .cb-hybrid {
  background-position: -17px 0px;
}
.cb-album a.next:hover,
.cb-album .next > .cb-hybrid:hover {
  background-position: -17px -18px;
}
.cb-album a.next:active,
.cb-album .next > .cb-hybrid:active {
  background-position: -17px -36px;
}
.cb-album .next > .cb-hybrid.fade {
  background-position: -17px -54px;
}
.cb-strips table {
  border-collapse: collapse;
}
td > .body {
  display: inline-block;
  vertical-align: middle;
}
.cb-strips-images {
  height: 100%;
  width: 100%;
}
.cb-strips-images > .foto {
  width: 100%;
  height: 100%;
  position: absolute;
}
.cb-strips-images > .foto > .cb-landscape {
  width: 100%;
  height: auto;
}
.cb-strips-images > .foto > .cb-portrait {
  width: auto;
  height: 100%;
}
.cb-strips-container > .body > .foto {
  height: 100% !important;
  overflow: hidden;
}
.cb-strips-thumbs a.foto {
  width: 100%;
}
.cb-strips-container > .body a.foto,
.cb-strips-thumbs .part > .foto > .foto {
  position: static;
  width: 100% !important;
  height: 100% !important;
}
.cb-strips-container .cb-landscape,
.cb-strips-container .cb-portrait {
  max-width: 100% !important;
  max-height: 100% !important;
}
.cb-strips-4 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 23% !important;
}
.cb-strips-6 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 14.66666667% !important;
}
.cb-strips-8 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 10.5% !important;
}
.cb-strips-10 .cb-strips-container .part.foto {
  margin: 1% !important;
  width: 8% !important;
}
.cb-matrix .body-non-mobile > .foto {
  margin: 3% !important;
}
.cb-matrix .body-non-mobile a.foto {
  position: static;
  height: 100% !important;
}
.cb-matrix-columns-1 .body-non-mobile > .foto {
  width: 94% !important;
}
.cb-matrix-columns-3 .body-non-mobile > .foto,
.cb-matrix-columns-5 .body-non-mobile > .foto,
.cb-matrix-columns-6 .body-non-mobile > .foto,
.cb-matrix-columns-7 .body-non-mobile > .foto,
.cb-matrix-columns-8 .body-non-mobile > .foto {
  width: 44% !important;
}
@media only screen and (min-width: 240px) {
  .cb-matrix-columns-2 .body-non-mobile > .foto,
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 44% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 10.66666667% !important;
  }
}
@media only screen and (min-width: 480px) {
  .cb-matrix-columns-3 .body-non-mobile > .foto {
    width: 27.33333333% !important;
  }
  .cb-matrix-columns-4 .body-non-mobile > .foto,
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 19% !important;
  }
  .cb-matrix-columns-5 .body-non-mobile > .foto,
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 14% !important;
  }
  .cb-matrix-columns-6 .body-non-mobile > .foto {
    width: 10.66666667% !important;
  }
}
@media only screen and (min-width: 1024px) {
  .cb-matrix-columns-7 .body-non-mobile > .foto {
    width: 8.28571429% !important;
  }
  .cb-matrix-columns-8 .body-non-mobile > .foto {
    width: 6.5% !important;
  }
}
.cb-contentflow .body-non-mobile .part {
  margin-right: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
}
.foto > .head {
  position: relative;
}
.cb-manual .desc,
.body-non-mobile > .desc {
  position: relative;
}
#view .cb-manual .desc .norm,
#view .body-non-mobile > .desc .norm {
  position: absolute;
  top: 0;
  left: 0;
}
.foto > .head > .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
}
.cb-album .ctrl {
  float: right;
}
#root div.foto div.body div.desc {
  float: left;
}
#root div.foto div.body div.desc div.part {
  float: none;
}
/******* module-statistic.less 2013-1-16 *******/
.cb-statistics .chartclient-annotation-table {
  font-size: 12px;
  font-size: 1.2rem;
}
.cb-statistic-annotation li {
  list-style: none;
}
.cb-statistics .ctrl {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}
.cb-statistics .ctrl input {
  margin-right: 5px;
}
.cb-statistics .ctrl label {
  margin-right: 15px;
}
.cb-statistics .ctrl a {
  float: right;
}
#root .goog-custom-button-inner-box table,
#root .chartclient-annotation-table,
#root .annotatedtimelinetable {
  margin: 0;
}
#root #scrollingListTd {
  padding: 5px 0 5px 4px;
}
#root div.cb-statistics table.horz thead th {
  border-style: none none solid;
  border-color: #555;
  background-color: transparent;
}
#root div.cb-statistics table.horz tbody td {
  padding: 2px 1px;
  border: solid #ddd;
  border-width: 1px 0 0;
}
#root div.cb-statistics table.horz .odd td {
  background-color: #f9f9f9;
}
.cb-statistics .cb-visits {
  text-align: right;
}
/******* development.less 2013-1-16 *******/
/*.unit {
  box-shadow: 0 0 0 1px red inset;
}
 .part {
   box-shadow: 0 0 0 1px blue inset;
}*/
/*# sourceMappingURL=./screen-small.css.map */